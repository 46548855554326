import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CabinsFullWidth from "../components/cabinsFullWidth"
import Haida from "../components/haida"
import ContactInfo from "../components/contactInfo"

const HaidawayCottage = () => {
  return (
    <Layout>
      <SEO title="Shipwreck Cabin" />
      <Haida />
      <ContactInfo />
      <CabinsFullWidth/>
    </Layout>
  )
}

export default HaidawayCottage
