import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import CabinInformation from '../elements/cabinInformation'
import {haidaData} from "../data/haidaData"

const Shipwreck = () => {

const imagesQuery = useStaticQuery(graphql`
  query {
    sliderImages: allFile(filter: {relativeDirectory: {eq: "haida"}, ext: {regex: "/(jpg)|(png)|(jpeg)/"}}, sort: {fields: relativePath, order: ASC}) {
      edges {
        node {
          id
          base
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`)


  return (
    <>
      <CabinInformation images={imagesQuery} data={haidaData} title="Haidaway Cottage"/>
    </>
  )
}

export default Shipwreck