export const haidaData = [
  {
    cabin: "Haidaway",
    airbnb: "https://www.airbnb.ca/rooms/20845861?source_impression_id=p3_1614719408_Fpz2rJsOt384fby9&guests=1&adults=1",
    about: "The Haidaway is a vacation cottage in Tofino next to Chesterman Beach. We have designed the Haidaway as a cheerful rental with red and yellow cedar decor. This cottage has a claw-foot bathtub from 1908 outside in a vine-covered solarium to help you relax during your vacation. It is suitable for 4 guests with a Queen-size bed and an accompanying double bed in the loft. It features both indoor and outdoor cold/hot showers, dishwasher and its own washer/dryer. There is free wireless and high-speed internet for those who need to stay connected as well as Optic TV/Stereo and Netflix. The Haidaway has both indoor and outdoor dining areas. The outdoor dining and BBQ are glass covered for rainy days.",
    amenities: [
      {
        id: 1,
        amenity: "Suitable for up to 4 guests, including children over 5 years old"
      },
      {
        id: 2,
        amenity: "Across the road from Chesterman Beach and only a short drive to Tofino"
      },
      {
        id: 3,
        amenity: "1 bedroom and loft cottage: cozy and cheerful rental with red and yellow cedar decor"
      },
      {
        id: 4,
        amenity: "1908 claw-foot bathtub outside in a vine-covered solarium to relax you during your vacation"
      },
      {
        id: 5,
        amenity: "Indoor and outdoor hot/cold showers"
      },
      {
        id: 7,
        amenity: "Optik TV/Stereo music, TV with full range of channels including Netflix"
      },
      {
        id: 8,
        amenity: "Queen-size bed"
      },
      {
        id: 9,
        amenity: "Double bed in the cottage's loft"
      },
      {
        id: 10,
        amenity: "BBQ for your special Tofino vacation meals"
      },
      {
        id: 11,
        amenity: "Skylights"
      },
      {
        id: 12,
        amenity: "Full kitchen with Gas cooking stove"
      },
      {
        id: 13,
        amenity: "Dishwasher"
      },
      {
        id: 14,
        amenity: "Gas Fireplace"
      },
      {
        id: 15,
        amenity: "Washer and Dryer"
      },
    ]
  },
]